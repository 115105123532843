// This file is compiled inside Decidim core pack. Code can be added here and will be executed
// as part of that pack

// Load images
require.context("../../images", true)


$("#thanks-message a").on('click', function(e){
	e.preventDefault();
  	href_value = $(this).attr('href')
	urlOK = href_value.startsWith('http://') || href_value.startsWith('https://')
	external_url = href_value.startsWith('/link?external_url')
	console.log("clicked??", href_value, urlOK, external_url );

	if (!external_url)
	{
		if (urlOK){
			window.open(href_value);
		}else{
			new_href = "https://"+href_value
	  		console.log("new_href>>>>", new_href)
	  		window.open(new_href);
		}
	}

})